import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-theme.min.css";
import { permissionMapper } from "./UserManagement";
import Menu from "./Menu";
import "./Account.css";
import Button from "react-bootstrap-button-loader";

import { properties } from "./properties";
import api from "./api";
import { CurrentUserContext } from "./contexts/CurrentUserContext";

class Account extends Component {
	request() {
		const user = this.context;
		api
			.get(`/password-change-request?email=${user.email()}&type=short`)
			.then(() => alert("Email with instructions was sent to " + user.email()));
	}

	render() {
		let info = [];
		const user = this.context;
		for (let key in user.attributes()) {
			if (key !== "can_open") {
				if (key === "permissions") {
					info.push(
						<li className="info-row">
							<b>{properties[key]}:</b>{" "}
							{permissionMapper(user.attributes()[key]) + "  "}
						</li>
					);
				} else {
					info.push(
						<li className="info-row">
							<b>{properties[key]}:</b> {user.attributes()[key] + "  "}
						</li>
					);
				}
			}
		}

		return (
			<div>
				<Menu history={this.props.history} />
				<div className="Account-root center-block">
					<div className="info-row  center-block">
						Your profile information:
						<ul>{info}</ul>
						<Button
							type="submit"
							bsStyle="primary"
							style={{ float: "right" }}
							onClick={() => this.request()}
						>
							Request password change
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

Account.contextType = CurrentUserContext;

export default Account;
