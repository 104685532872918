import React from "react";
import { AddImageModal } from "../../FullData";
import api from "../../api";
import { properties } from "../../properties";
import Menu from "../../Menu";
import Auth from "../../Auth";
import { MaterialPage } from "./MaterialPage";
import { EquipmentPage } from "./EquipmentPage";

export class FullPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			item: {
				type: "",
			},
			images: [],
			documentsList: [],
		};
	}

	componentDidMount() {
		let operator = new URLSearchParams(window.location.search).get("operator");
		let material = new URLSearchParams(window.location.search).get("material");

		let url = properties.apiUrl;
		if (
			sessionStorage.getItem("item_url") &&
			sessionStorage.getItem("item_url") !== null
		) {
			url = sessionStorage.getItem("item_url");
		} else if (
			localStorage.getItem("item_url") &&
			localStorage.getItem("item_url") !== null
		) {
			url = localStorage.getItem("item_url");
			sessionStorage.setItem("item_url", url);
		}

		api
			.post(
				`/item/type-info?operator=${encodeURIComponent(operator)}&material=${material}`,
				{ url }
			)
			.then((json) => {
				this.setState({
					item: {
						operator,
						material,
						part_number: json.data.part_number,
						type: json.data.type || "material",
					},
				});
			})
			.catch((error) => {
				this.setState({
					item: {
						operator,
						material,
						part_number: "",
						type: "material",
					},
				});
			});
		this.getDocumentsList(operator, material);
	}

	getDocumentsList = (operator, material) => {
		api
			.post(`/documents/get`, { operator, material })
			.then((json) => {
				this.setState({
					documentsList: json.data,
				});
			})
			.catch((error) => console.log(error));
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.item !== this.state.item) {
			this.get_ids();
			this.getDocumentsList(this.state.item.operator, this.state.item.material);
		}
	}

	onModalClose = () => {
		this.setState({ showModal: false, image: "" });
		this.get_ids();
	};

	getImgById(id) {
		const config = Auth.headers();
		config["responseType"] = "blob";

		let data = {
			_id: id,
		};

		api
			.post(`/image/id`, data, config)
			.then((response) => {
				let matrixBlob = new Blob([response.data], { type: "image/jpg" });
				let fileReader = new FileReader();
				const header = response.headers["content-disposition"];
				const filename = /filename=(.*)/.exec(header)[1].replace(/"/g, "");
				fileReader.readAsDataURL(matrixBlob);
				fileReader.onload = () => {
					let result = fileReader.result;
					this.setState((state) => ({
						images: state.images.concat({
							image: result,
							active: filename === "True" ? 1 : 0,
						}),
					}));
				};
			})
			.catch((error) => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				} else {
					console.log(error);
				}
			});
	}

	get_ids() {
		let data = {
			material: this.state.item.material,
			type: "img",
			operator: this.state.item.operator,
			part_number: this.state.item.part_number || "",
		};

		api
			.post(`/images`, data)
			.then((response) => {
				this.setState({
					ids: response.data,
					images: [],
					order: [],
					actives: [],
				});
				for (let id in response.data) {
					this.getImgById(response.data[id]);
				}
			})
			.catch((error) => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				} else {
					this.setState({ ids: [], images: [], order: [], actives: [] });
					console.log(error);
				}
			});
	}

	updateItem = (part_number) => {
		if (part_number !== this.state.item.part_number) {
			this.setState({
				item: {
					...this.state.item,
					part_number: part_number,
				},
			});
		}
	};

	render() {
		return (
			<div>
				<Menu history={this.props.history} />
				{this.state.item.type === "material" && (
					<MaterialPage
						documentsList={this.state.documentsList}
						image={this.state.image}
						onItemUpdate={this.updateItem}
						show={true}
						getDocumentsList={this.getDocumentsList}
						images={this.state.images}
						openImageModal={() => this.setState({ showModal: true })}
					/>
				)}

				{this.state.item.type === "equipment" && (
					<EquipmentPage
						onItemUpdate={this.updateItem}
						getDocumentsList={this.getDocumentsList}
						documentsList={this.state.documentsList}
						image={this.state.image}
						show={true}
						images={this.state.images}
						openImageModal={() => this.setState({ showModal: true })}
					/>
				)}

				{this.state.showModal && (
					<AddImageModal
						show={this.state.showModal}
						onClose={this.onModalClose}
						item={this.state.item}
					/>
				)}
			</div>
		);
	}
}
