import React from "react";
import { AntdTable } from "../../components/custom/AntdTable";
import { createIHLink } from "./BomDataTab";
import { DEFAULT_OPERATOR } from "../../properties";
import { getFilterValue } from "../../helpers";

export function WhereUsedTable({ equipment }) {
	const columns = [
		{
			key: "label",
			title: "",
			dataIndex: "label",
		},{
			key: "material",
			title: "Asset",
			dataIndex: "equipment",
			render: (floc, item) =>
				createIHLink(item.operator || DEFAULT_OPERATOR, floc),
		},
		{
			key: "floc",
			title: "Functional Location",
			dataIndex: "floc",
			onFilter: (value, record) => record["floc"] === value,
			filters: getFilterValue(equipment, "floc"),
		},{
			key: "bom",
			title: "BOM",
			dataIndex: "bom_number",
			onFilter: (value, record) => record["bom_number"] === value,
			filters: getFilterValue(equipment, "bom_number"),
		},{
			key: "type",
			title: "Type",
			dataIndex: "type",
		},
		{
			key: "order",
			title: "Order",
			dataIndex: "wo_number",
		},

		{
			key: "area",
			title: "Area",
			dataIndex: "area",
			onFilter: (value, record) => record["area"] === value,
			filters: getFilterValue(equipment, "area"),
		},
		{
			key: "quantity",
			title: "Ordered Quantity",
			dataIndex: "ordered_quantity",
			render: (ordered_quantity, item) => {
				if (item.type === 'MWO') {
					return item.quantity
				}
				return ordered_quantity
			}
		},
		{
			key: "quantity",
			title: "Installed Quantity",
			dataIndex: "installed_quantity",
			render: (ordered_quantity, item) => {
				if (item.type === 'EPL') {
					return item.quantity
				}
				return ordered_quantity
			}
		},
		{
			key: "unit",
			title: "Unit",
			dataIndex: "unit",
		},
	];

	const getFullRows = () => {
		if (equipment) {
			let rows = [...equipment];
			let installed = equipment
				.map((v) => v.type === 'EPL'? parseInt(v.quantity) : 0)
				.reduce((a, b) => a + b, 0);
			let ordered = equipment
				.map((v) => {
					return v.type === 'MWO' ? parseInt(v.quantity) : 0
				})
				.reduce((a, b) => a + b, 0);

			rows.push({
				key: 'total',
				label: <b>Total Quantity</b>,
				installed_quantity: installed,
				ordered_quantity: ordered
			});
			return rows;
		} else {
			return [];
		}
	};

	return (
		<AntdTable
			tableSize={"small"}
			scroll={300}
			data={getFullRows()}
			columns={columns}
		/>
	);
}
