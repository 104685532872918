import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { StructuredData } from "./StructuredData";
import { CommonalityTable } from "./CommonalityTable";
import { StockOnHandTab } from "./StockOnHandTab";
import { WhereUsedTable } from "./WhereUsedTable";
import { TransactionsTab } from "./TransactionsTab";
import { UsageTab } from "./UsageTab";
import { allowAddingDocuments, showAnalyticsTabs } from "../../properties";
import { AttachedDocuments } from "./AttachedDocuments";
import { HersTable } from "./HersTable";

const { TabPane } = Tabs;

export function MaterialInfoTabs({
	item,
	usageInfo,
	transactionsInfo,
	documentsList = [],
	updateDocsTable,
	hers = [],
	commonality,
	stockData,
	equipment,
	structured,
	dashboardSummary,
	focusSummary,
}) {
	const [activeKey, setActiveKey] = useState("2");

	useEffect(() => {
		if (structured.length > 0) {
			setActiveKey("1");
		}
	}, [structured]);

	return (
		<Tabs
			defaultActiveKey={"1"}
			activeKey={activeKey}
			onTabClick={setActiveKey}
		>
			{structured && structured.length > 0 && (
				<TabPane
					tab={"Description"}
					key={"1"}
				>
					<StructuredData structured={structured} />
				</TabPane>
			)}
			<TabPane
				tab={"Stock on Hand"}
				key={"2"}
			>
				<StockOnHandTab
					data={stockData}
					item={item}
				/>
			</TabPane>
			<TabPane
				tab={`Usage (${usageInfo.count || 0})`}
				key={"3"}
			>
				<UsageTab
					item={item}
					usageInfo={usageInfo}
				/>
			</TabPane>
			<TabPane
				tab={`Where-Used (${equipment.length || 0})`}
				key={"4"}
			>
				<WhereUsedTable equipment={equipment} />
			</TabPane>
			{showAnalyticsTabs && (
				<TabPane
					tab={"Dashboard Summary"}
					key={"5"}
				>
					<StructuredData structured={dashboardSummary} />
				</TabPane>
			)}
			{showAnalyticsTabs && (
				<TabPane
					tab={"Focus Summary"}
					key={"6"}
				>
					<StructuredData structured={focusSummary} />
				</TabPane>
			)}
			<TabPane
				tab={`Transactions (${(transactionsInfo.tableData && transactionsInfo.tableData.length) || 0})`}
				key={"7"}
			>
				<TransactionsTab transactionsInfo={transactionsInfo} />
			</TabPane>
			<TabPane
				tab={`Potential Duplicates (${commonality.length || 0})`}
				key={"8"}
			>
				<CommonalityTable
					item={item}
					commonality={commonality}
				/>
			</TabPane>
			{allowAddingDocuments && (
				<TabPane
					tab={`Attached Documents (${documentsList.length || 0})`}
					key={"9"}
				>
					<AttachedDocuments
						item={item}
						documentsList={documentsList}
						updateDocsTable={updateDocsTable}
					/>
				</TabPane>
			)}
			{hers && hers.length > 0 && (
				<TabPane
					tab={`HERS Records (${hers.length || 0})`}
					key={"10"}
				>
					<HersTable hers={hers} />
				</TabPane>
			)}
		</Tabs>
	);
}
