import React from "react";
import { Descriptions } from "antd";
import { beautifyKey } from "./HeaderInfo";

export function StructuredData({ structured }) {
	const getLabel = (field) => {
		const mapping = {
			ratio: "PM:CM Ratio",
			cm_executed: "CM Work Executed",
			pm_executed: "PM Work Executed",
			pm_plans: "PM Maintenance Plans",
			identical_boms: "Identical BOMs",
		};
		return mapping[field] || beautifyKey(field);
	};

	return (
		<Descriptions
			size="small"
			bordered
		>
			{structured.map((row) => {
				return (
					<Descriptions.Item
						span={3}
						labelStyle={{ width: "20%" }}
						label={<b>{getLabel(row.key)}</b>}
					>
						{row.value}
					</Descriptions.Item>
				);
			})}
		</Descriptions>
	);
}
