export const getFilterValue = (array = [], _key) => {
	const values = [...array].map((v) => v[_key]);
	const unique = new Set(values);
	return [...unique].map((v) => {
		return {
			text: v,
			value: v,
		};
	});
};
