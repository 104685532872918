import React from "react";
import { Button, Dropdown, Menu as DropdownMenu } from "antd";

import { operatorToProject, properties } from "./properties";

import { LinkOutlined } from "@ant-design/icons";

export function LinkOutlinedButton({ onClick, ...rest }) {
	return (
		<Button
			icon={<LinkOutlined />}
			onClick={onClick}
			type={"link"}
			{...rest}
		/>
	);
}

export function EntityLinksDropdown({
	operator,
	material,
	type,
	sortCode,
	triggerStyle = {},
}) {
	const items = (
		<DropdownMenu>
			{properties.analyticsLink && (
				<DropdownMenu.Item>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${properties.analyticsLink}/projects/${operatorToProject(operator)}/min-max-material-analysis?material=${material}`}
					>
						Workbench
					</a>
				</DropdownMenu.Item>
			)}
			{properties.ihLink && (
				<DropdownMenu.Item>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${properties.ihLink}/data?operator=${operator}&material=${material}`}
					>
						Inventory
					</a>
				</DropdownMenu.Item>
			)}
			{properties.khLink && (
				<DropdownMenu.Item>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${properties.khLink}&q=${sortCode ? (sortCode !== "NA" ? sortCode : material) : material}`}
					>
						Knowledge
					</a>
				</DropdownMenu.Item>
			)}
			{properties.dmLink && (
				<DropdownMenu.Item>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${properties.dmLink}/dm?search=${material}&batch=${type}-mapped-batch`}
					>
						Engineering
					</a>
				</DropdownMenu.Item>
			)}
			{properties.assetHubLink && (
				<DropdownMenu.Item>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${properties.assetHubLink}/entity_links?type=${type}&item=${material}`}
					>
						Equipment
					</a>
				</DropdownMenu.Item>
			)}
		</DropdownMenu>
	);

	return (
		<Dropdown overlay={items}>
			<LinkOutlinedButton style={{ color: "#004987" }} />
		</Dropdown>
	);
}
