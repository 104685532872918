import React, { createContext, useEffect, useState } from "react";
import api from "../api";

class CurrentUser {
	name() {
		return "";
	}

	email() {
		return "";
	}

	attributes() {
		return {};
	}

	company() {
		return "";
	}

	canOpen() {
		return false;
	}
}

export const CurrentUserContext = createContext();

export const CurrentUserProvider = ({ children }) => {
	const [value, setValue] = useState(new CurrentUser());

	useEffect(() => {
		const parseResponse = (response) => {
			setValue(new LoadedCurrentUser(response.data));
		};

		api.get("/get_current_user").then(parseResponse);
	}, []);

	return (
		<CurrentUserContext.Provider value={value}>
			{children}
		</CurrentUserContext.Provider>
	);
};

class LoadedCurrentUser extends CurrentUser {
	constructor(apiPayload) {
		super();

		this._data = apiPayload;
	}

	name() {
		return this._data.name;
	}

	email() {
		return this._data.email;
	}

	company() {
		return this._data.company;
	}

	attributes() {
		return {};
	}

	canOpen() {
		return this._data.canOpen;
	}
}
