import { properties } from "./properties";

export function permissionMapper(permissions) {
	let perms = [];
	for (let i = 0; i < permissions.length; i++) {
		if (permissions[i] === "without") {
			perms.push(properties.permission_regular);
		} else if (permissions[i] === "view") {
			perms.push(properties.permission_read);
		} else if (permissions[i] === "internal") {
			perms.push(properties.permission_internal);
		} else if (permissions[i] === "operator") {
			perms.push(properties.permission_operator);
		} else if (permissions[i] === "admin") {
			perms.push(properties.permission_admin);
		} else if (permissions[i] === "superadmin") {
			perms.push(properties.permission_super);
		}
	}
	return perms;
}
