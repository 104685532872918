import { loadUIConfig } from "@digatex/digatex-ui-lib";
import ProjectUIConfig from "./config/ui-config.json";

export const properties = {
	apiUrl: process.env.REACT_APP_HOST,
	company: "Company",
	showAll: true,
	email: "Email",
	firstName: "First Name",
	lastName: "Last Name",
	phone: "Phone",
	permissions: "Permissions",
	location: "Location",
	position: "Position",
	permission_admin: "System Administrator from Operator",
	permission_regular: "Regular User",
	permission_operator: "Nominated Contact Person",
	permission_read: "Read Only",
	permission_internal: "Internal user",
	permission_super: "Super Administrator",
	previewRowsPerPage: 10,
	ihLink: process.env.REACT_APP_IH_LINK,
	dmLink: process.env.REACT_APP_DM_LINK,
	khLink: process.env.REACT_APP_KH_LINK,
	analyticsLink: process.env.REACT_APP_WB_LINK,
	assetHubLink: process.env.REACT_APP_EH_LINK,
	isDebug: process.env.REACT_APP_IS_DEBUG || false,
	showProposedBomTab: process.env.REACT_APP_SHOW_PROPOSED_BOM_TAB || false,
	plansMappingKey: process.env.REACT_APP_PLANS_MAPPING_KEY || "sort_field",
};

export const KEYCLOAK_AUTH_SERVER_URL =
	process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL;

export const DEFAULT_OPERATOR = process.env.REACT_APP_DEFAULT_OPERATOR;

export const showContextLinks = process.env.REACT_APP_SHOW_CONTEXT === "true";

export const maxFileSizeMB = process.env.REACT_APP_MAX_FILE_SIZE_MB || 5;

export const showAnalyticsTabs =
	process.env.REACT_APP_REQUIRED_ANALYTICS_TAB === "true";
export const allowAddingDocuments =
	process.env.REACT_APP_ALLOW_DOCUMENTS === "true";

export const projectCurrency = process.env.REACT_APP_CURRENCY || "";

export const ROP_MIN_LABEL = process.env.REACT_APP_ROP_MIN_LABEL || "ROP";

export const USER_MANAGEMENT_LINK =
	process.env.REACT_APP_USER_MANAGEMENT_LINK || "";

const operatorProjectPairs =
	process.env.REACT_APP_OPERATOR_TO_PROJECT_MAPPING?.split(",") || [];
const operatorToProjectMap = new Map(
	operatorProjectPairs.map((pair) => pair.split(":"))
);

export function operatorToProject(operator) {
	return operatorToProjectMap.get(operator) || "6295c7173ae8947d89af2c73";
}

export const UI_CONFIG = ProjectUIConfig.dummy
	? loadUIConfig()
	: ProjectUIConfig;
