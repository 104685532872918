import React from "react";
import { Route } from "react-router";

export function RouteWithLayout({ layout, component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => {
				return React.createElement(
					layout,
					props,
					component && React.createElement(component, { ...props, ...rest })
				);
			}}
		/>
	);
}
