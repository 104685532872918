import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

export function configureStore(preloadedState) {
	return createStore(
		combineReducers({
			router: connectRouter(history),
		}),
		preloadedState,
		compose(applyMiddleware(routerMiddleware(history), thunkMiddleware))
	);
}
