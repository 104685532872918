import React, { useEffect, useState } from "react";
import "../../App.css";
import { AntdTable } from "../../components/custom/AntdTable";
import api from "../../api";
import { getLabel } from "./labelMapper";
import { getFilterValue } from "../../helpers";
import { Statistic } from "antd";
import { projectCurrency, ROP_MIN_LABEL } from "../../properties";

export function StockOnHandTab({ item, data = [] }) {
	const [keys, setKeys] = useState([]);
	const [overridenKeys, setOverridenKeys] = useState([]);

	useEffect(() => {
		api
			.get("/settings/table-default?name=Stock-on-Hand")
			.then((json) => setKeys(json.data["columns"]));

		api
			.get("/settings/table-overriden?name=Stock-on-Hand")
			.then((json) => setOverridenKeys(json.data["columns"]))
			.catch((error) => setOverridenKeys(null));
	}, [item]);

	const getColumns = () => {
		if (overridenKeys) {
			return columns.filter((column) => overridenKeys.includes(column["key"]));
		} else if (keys) {
			return columns.filter((column) => keys.includes(column["key"]));
		} else {
			return columns;
		}
	};

	const [showAll, setShowAll] = useState(false);

	const getSurplus = (row) => {
		const max = parseInt(row["MAX"]);
		const stock = parseInt(row["plant_stock_level"]);
		if (stock > max) {
			return stock - max;
		}
		return 0;
	};

	const columns = [
		{
			key: "label",
			title: getLabel("label"),
			dataIndex: "label",
			render: (label) => <b>{label}</b>,
		},
		{
			key: "plant",
			title: getLabel("plant"),
			dataIndex: "plant",
			onFilter: (value, record) => record["plant"] === value,
			filters: getFilterValue(data, "plant"),
		},
		{
			key: "new_plant",
			title: "New Plant",
			dataIndex: "new_plant",
		},
		{
			key: "plant_location",
			title: getLabel("plant_location"),
			dataIndex: "plant_location",
			onFilter: (value, record) => record["plant_location"] === value,
			filters: getFilterValue(data, "plant_location"),
		},
		{
			key: "bin_location",
			title: getLabel("bin_location"),
			dataIndex: "bin_location",
			onFilter: (value, record) => record["bin_location"] === value,
			filters: getFilterValue(data, "bin_location"),
		},
		{
			key: "unit",
			title: getLabel("unit"),
			dataIndex: "unit",
		},
		{
			key: "plant_stock",
			title: getLabel("plant_stock"),
			dataIndex: "plant_stock_level",
			render: (stock, item) => (
				<Statistic
					valueStyle={{ fontSize: 14 }}
					value={!item.label ? parseInt(stock || item.stock_level || 0) : stock}
				/>
			),
		},
		{
			key: "plant_price",
			title: getLabel("plant_price") + ` (${projectCurrency})`,
			dataIndex: "plant_price",
		},
		{
			key: "surplus_level",
			title: getLabel("surplus_level"),
			dataIndex: "surplus_level",
			render: (stock, record) => (
				<Statistic
					valueStyle={{ fontSize: 14 }}
					value={getSurplus(record) || 0}
				/>
			),
		},
		{
			key: "rop",
			title: getLabel("rop"),
			dataIndex: "ROP",
			render: (stock) => (
				<Statistic
					valueStyle={{ fontSize: 14 }}
					value={parseInt(stock || 0)}
				/>
			),
		},
		{
			key: "lot_size",
			title: getLabel("lot_size"),
			dataIndex: "lot_size",
			render: (stock, item) => !item.label && parseInt(stock || 0),
		},
		{
			key: "rounding_value",
			title: getLabel("rounding_value"),
			dataIndex: "rounding_value",
			render: (stock, item) => !item.label && parseInt(stock || 0),
		},
		{
			key: "max",
			title: getLabel("max"),
			dataIndex: "MAX",
			render: (stock) => (
				<Statistic
					valueStyle={{ fontSize: 14 }}
					value={parseInt(stock || 0)}
				/>
			),
		},
		{
			key: "mrp_type",
			title: getLabel("mrp_type"),
			dataIndex: "MRP Type",
			onFilter: (value, record) => record["MRP Type"] === value,
			filters: getFilterValue(data, "MRP Type"),
		},
		{
			key: "lead_time",
			title: getLabel("lead_time"),
			dataIndex: "Lead Time in Days",
			render: (stock, item) => !item.label && parseInt(stock || 0),
		},
	];

	const getFullRows = () => {
		if (data) {
			let rows = [...data];
			if (!showAll) {
				rows = rows.filter(
					(row) => parseInt(row.plant_stock_level || item.stock_level) !== 0
				);
			}
			let max = data.map((v) => parseInt(v.MAX)).reduce((a, b) => a + b, 0);
			let min = data.map((v) => parseInt(v["ROP"])).reduce((a, b) => a + b, 0);
			let surplus = data
				.map((v) => parseInt(v["surplus_level"]))
				.reduce((a, b) => a + b, 0);
			let stock_level = data
				.map((v) => parseInt(v["plant_stock_level"] || item.stock_level))
				.reduce((a, b) => a + b, 0);
			rows.push({
				label: "Total Units",
				MAX: max,
				ROP: min,
				lot_size: "",
				plant_stock_level: stock_level,
				surplus_level: surplus,
			});
			rows.push({
				label: `Total Value (${projectCurrency})`,
				MAX: max * item["price"],
				ROP: min * item["price"],
				plant_stock_level: parseInt(stock_level * item["price"] * 100) / 100,
				surplus_level: surplus * item["price"],
			});
			return rows;
		} else {
			return [];
		}
	};

	return (
		<div>
			<div
				style={{ float: "right", color: "#004987" }}
				className="beautiful-link"
				onClick={() => setShowAll(!showAll)}
			>
				{showAll ? "Hide" : "Show"} zero-stock locations
			</div>
			<AntdTable
				tableSize={"small"}
				scroll={280}
				data={getFullRows()}
				columns={getColumns()}
			/>
		</div>
	);
}
