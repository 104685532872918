import axios from "axios";
import Auth from "./Auth";

class api {
	constructor(url) {
		this.baseURL = url;
	}

	get(uri, config = {}) {
		return axios.get(this.baseURL + uri, this._withHeaders(config));
	}

	post(uri, data = {}, config = {}) {
		return axios.post(this.baseURL + uri, data, this._withHeaders(config));
	}

	put(uri, data = {}, config = {}) {
		return axios.put(this.baseURL + uri, data, this._withHeaders(config));
	}

	delete(uri, config = {}) {
		return axios.delete(this.baseURL + uri, this._withHeaders(config));
	}

	_withHeaders(config) {
		return {
			headers: Auth.headers(),
			...config,
		};
	}
}

export const TransactionsApi = new api(
	process.env.REACT_APP_TRANSACTIONS_API_URL
);
export default new api(process.env.REACT_APP_HOST + "/api");
