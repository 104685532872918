import React from "react";
import { Modal } from "antd";

export function AntdModal({
	title,
	isModalVisible,
	onCancel,
	onOk,
	children,
	headStyle = {},
}) {
	return (
		<Modal
			width={"65vw"}
			title={<div style={headStyle}>{title}</div>}
			open={isModalVisible}
			onCancel={onCancel}
			onOk={onOk}
		>
			{children}
		</Modal>
	);
}
